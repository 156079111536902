export function validate(value, validator) {
  if (typeof validator === 'string') {
    if (validator === 'null') {
      if (value === null) {
        return null;
      } else {
        throw new Error(`Expected ${validator}, got null`);
      }
    }

    if (typeof value !== validator) {
      throw new Error(`Expected ${validator}, got ${typeof value}`)
    }

    return value;
  }

  if (validator instanceof RegExp) {
    if (typeof value !== 'string') {
      throw new Error(`Expected a string, got ${value === null ? 'null' : typeof value}`);
    }

    if (!validator.test(value)) {
      throw new Error(`String '${value}' doesn't match ${validator}`);
    }

    return value;
  }

  if (typeof validator === 'object' && validator !== null) {
    if (typeof value !== 'object' || value === null) {
      throw new Error(`Expected object, got ${value === null ? 'null' : typeof value}`);
    }

    const result = {};

    for (const key in validator) {
      try {
        result[key] = validate(value[key], validator[key]);
      } catch (err) {
        throw new Error(`${key}: ${err.message}`);
      }
    }

    return result;
  }

  if (typeof validator === 'function') {
    return validator(value);
  }

  throw new Error(`Unknown validator: [${typeof validator}] ${validator}`);
}

validate.withMessage = (msg='$1') => (value, validator) => {
  try {
    return validate(value, validator);
  } catch (err) {
    console.error('validate.withMessage() caught the following error:', err);
    throw new Error(msg.replace('$1', err))
  }
};

validate.isMap = (validator) => (value) => {
  if (typeof value !== 'object' || value === null) {
    throw new Error(`Expected object, got ${value === null ? 'null' : typeof value}`);
  }

  const result = {};

  for (const key in value) {
    try {
      result[key] = validate(value[key], validator);
    } catch (err) {
      throw new Error(`${key}: ${err.message}`);
    }
  }

  return result;
};


validate.isArray = (itemValidator, ...validators) => (value) => {
  if (!Array.isArray(value)) {
    throw new Error('Expected array');
  }

  const arr = value.map(item => validate(item, itemValidator));

  return validators.reduce((arr, validator) => validate(arr, validator), arr)
};

validate.len = ({min = 0, max = Infinity}) => (value) => {
  const len = value.length;

  if (typeof len !== 'number') {
    throw new Error(`length property must be a number, not ${typeof len}`);
  }

  if (len < min) {
    throw new Error(`length ${len} must be at least ${min}`);
  }

  if (len > max) {
    throw new Error(`length ${len} must be no more than ${max}`);
  }

  return value;
};

validate.either = (...validators) => (value) => {
  const errors = [];

  for (const validator of validators) {
    try {
      return validate(value, validator);
    } catch (err) {
      errors.push(err);
    }
  }

  throw new Error(`Multiple errors { ${errors.map(err => err.message).join('; ')} }`);
};

validate.optional = (validator, defaultValue=undefined) => (value) => {
  if (value === undefined) {
    return defaultValue;
  }

  return validate(value, validator);
};

validate.oneOf = (...values) => {
  const valueSet = new Set(values);

  return (value) => {
    if (!valueSet.has(value)) {
      throw new Error(`must be one of: ${values.join(', ')}`);
    }

    return value;
  };
};
