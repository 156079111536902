import {
  component,
  mount,
  observable,
  elems,
} from '/modules/lui.js';
import { Navigation } from '/modules/lui-nav.js';
import { Client } from '/modules/client.js';
import { icons } from '/modules/components.js';
import { Clock } from '/modules/clock.js';
import { DataManager } from '/modules/data-manager.js';
import homePage from '/home.js';
import entryPage from '/entry.js';
import entriesPage from '/entries.js';
import categoriesPage from '/categories.js';
import summaryPage from '/summary.js';
import { isRefreshing } from '/modules/pull-to-refresh.js';

const clock = new Clock();

const nav = new Navigation();
nav.registerLinkInterceptor(document.body);
window.nav = nav;

const client = new Client();

const dataMgr = new DataManager(client);
dataMgr.init();

window.addEventListener('focus', () => {
  // Refresh time and data when the window is focused again
  clock.tick();
  dataMgr.fetchEntries();
});

const headerColour = '#123456';

const titleText = observable.value('');
titleText
  .map(title => `${title}${!title || title === 'Timer' ? 'Timer' : ' - Timer'}`)
  .onChange(title => {
    document.title = title;
  });

const topContent = observable.value();
const upButtonTarget = observable.value();
const menuAction = observable.value();

const root = component(({children}, {div, nav: navbar, button, h1}) => [
  div({cls: 'topbar sticky-top', visible: titleText, style: {backgroundColor: headerColour}})(
    navbar({cls: 'navbar fs-3 justify-content-center text-white', style: {backgroundColor: headerColour}})(
      button({
        cls: 'btn border-0 position-absolute p-2 text-white transition-none',
        style: {left: '2px'},
        onclick: () => {
          const target = upButtonTarget.get() ?? '/';

          if (typeof target === 'function') {
            target();
          } else {
            nav.go(target);
          }
        },
        visible: upButtonTarget.map(up => up ?? '/'),
      })(icons.chevronLeft),
      h1({cls: 'm-0 fs-2'})(titleText),
      button({
        cls: 'btn border-0 position-absolute p-2 text-white transition-none',
        style: {right: '2px'},
        onclick: () => {
          const action = menuAction.get();
          if (action) {
            action();
          }
        },
        visible: menuAction,
      })(icons.threeDots),
    ),
    topContent,
  ),
  children,
]);

const { meta } = elems;
document.getElementsByTagName('head')[0].appendChild(meta({
  name: 'theme-color',
  content: observable.func(
    (categoryCol, title) => title ? headerColour : categoryCol,
    dataMgr.observable.currentCategory.map(cat => cat?.colour || '#7f7f7f'),
    titleText,
  ),
})().domElement());

// The "page" content that changes
const content = elems.main()();

mount(document.body, root()(content));

// A very crude loading indicator
isRefreshing.onChange(refreshing => {
  if (refreshing) {
    document.body.style.opacity = 0.5;
  }
});

let mountedRef;

function mountContent(elems, opts={}) {
  const {
    scrollToTop=true,
    ref,
  } = opts;

  // If the given ref is undefined (or falsey) or not equal to the previous
  // reference, then replace the content
  if (!ref || ref !== mountedRef) {
    content.removeChildren();
    content.append(elems);
    mountedRef = ref;
  }

  if (scrollToTop) {
    window.scroll(0, 0);
  }
}

const setPageInfo = (opts = {}) => {
  const { title, up } = opts;

  if (title !== undefined) {
    titleText.set(title);
  }

  upButtonTarget.set(up ?? '/');
  menuAction.set(opts.menuAction);
  topContent.set(opts.topContent);
};

const pages = [
  homePage,
  entryPage,
  entriesPage,
  categoriesPage,
  summaryPage,
];

for (const page of pages) {
  page({
    nav,
    client,
    setPageInfo,
    clock,
    dataMgr,
    mount: mountContent,
  });
}

nav.onNotFound(() => {
  mountContent(elems.div()('Not found'));
});

dayjs.extend(dayjs_plugin_advancedFormat);

nav.go(window.location);
