import {
  component,
  Viewmodel,
} from '/modules/lui.js';
import { shouldHaveDarkComplement } from '/modules/common.js';

class CategoriesViewmodel extends Viewmodel {
  constructor(dataMgr) {
    super({
      categories: dataMgr.observable.categories,
    });
  }
}

const categoryView = component(({item: category}, {div}) => (
  div({
    cls: 'rounded-3 p-2 px-3',
    style: {
      backgroundColor: category.colour,
      color: shouldHaveDarkComplement(category.colour) ? '#000000' : '#ffffff',
    },
  })(
    div({cls: 'fs-5'})(category.name),
    div()(category.id),
  )
));

const categoriesView = component(({viewmodel}, {div, list}) => (
  list({
    elem: div.withProps({
      cls: 'p-2 my-2 d-flex flex-column',
      style: {
        gap: '0.5em',
      },
    }),
    items: viewmodel.observable.categories,
    itemView: categoryView.withProps({
      onclick: (template) => viewmodel.startTemplate(template),
    }),
  })
));

export default ({nav, setPageInfo, mount, dataMgr}) => {
  nav.route('/categories').do(() => {
    const viewmodel = new CategoriesViewmodel(dataMgr);
    setPageInfo({title: 'Categories'});
    mount(categoriesView({viewmodel})());
  });
};
