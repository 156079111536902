import {
  component,
  Viewmodel,
  obs,
} from '/modules/lui.js';
import { timeDial } from  '/time-dial.js';
import {
  shouldHaveDarkComplement,
  findEntryBefore,
  findEntryAfter,
  formatDiff,
} from '/modules/common.js';

const dedupe = (f) => (entry, i, entries) => f(entry) && (i === 0 || !entries[i-1].start.isSame(entry.start));
const now = () => dayjs().startOf('minute');

class CategorySelectorViewmodel extends Viewmodel {
  constructor(dataMgr, onSelect) {
    super({
      categories: dataMgr.observable.categories,
      filter: '',
      focused: true,

      filteredCategories: ({categories, filter}) => {
        if (!filter) {
          return categories;
        }

        return categories.filter(cat => cat.name.toLowerCase().includes(filter.toLowerCase()));
      },
    });

    this.onSelect = onSelect;
  }

  setCategory(category) {
    this.onSelect(category);
    this.reset();
  }

  reset() {
    this.filter = '';
  }
}

class EntryViewmodel extends Viewmodel {
  constructor({client, dataMgr, nav, clock}) {
    super({
      time: clock.time,
      up: '',

      mode: '',

      deleting: false,
      submitting: false,
      menuVisible: false,

      editingEntry: null,
      startTime: now(),
      category: null,
      detail: '',
      comments: '',

      loading: false,
      error: '',
      categories: dataMgr.observable.categories,
      allEntries: dataMgr.observable.entries,

      // entries: ({allEntries, editingEntry}) => editingEntry ? allEntries.filter(entry => entry.id !== editingEntry.id) : allEntries,
      entries: ({allEntries, editingEntry}) => allEntries.filter(dedupe(entry => entry.id !== editingEntry?.id)),

      suggestedDetails: ({allEntries, category}) => {
        if (!category) {
          return [];
        }

        const seen = new Set();

        return allEntries.reduce((result, entry) => {
          if (entry.category === category.id && entry.detail && !seen.has(entry.detail)) {
            result.push(entry.detail);
            seen.add(entry.detail);
          }

          return result;
        }, []);
      },

      upAction: ({up, mode}) => mode === '' ? (up || '/') : () => this.reset(),

      displayCategories: ({categories, editingEntry}) => editingEntry ? categories : [null, ...categories],

      isEditing: ({editingEntry}) => !!editingEntry,

      colour: ({category}) => category?.colour || '#ffffff',
      darkText: ({colour}) => shouldHaveDarkComplement(colour),

      changed: ({editingEntry, startTime, category, detail, comments}) => editingEntry && (
        !editingEntry.start.isSame(startTime) ||
        category?.id !== editingEntry.category ||
        detail !== (editingEntry.detail || '') ||
        comments !== (editingEntry.comments || '')
      ),

      canSubmit: ({category, loading, submitting, editingEntry, changed}) => category && !loading && !submitting && (!editingEntry || changed),

      beforeEntry: ({startTime, entries}) => findEntryBefore(startTime, entries, true),
      beforeEntryCategory: ({beforeEntry, categories}) => beforeEntry ? categories.find(cat => cat.id === beforeEntry.category) : null,

      afterEntry: ({startTime, entries}) => findEntryAfter(startTime, entries),
      afterEntryCategory: ({afterEntry, categories}) => afterEntry ? categories.find(cat => cat.id === afterEntry.category) : null,
      afterEntryEnd: ({time, afterEntry, entries}) => (afterEntry && findEntryAfter(afterEntry.start, entries)?.start) || time,

      endTime: ({time, afterEntry}) => afterEntry ? afterEntry.start : time,

      submitText: ({isEditing, afterEntry}) => {
        if (isEditing) {
          return 'Save';
        } else if (afterEntry) {
          return 'Add';
        } else {
          return 'Start';
        }
      },
    });

    this.categorySelectVM = new CategorySelectorViewmodel(dataMgr, (cat) => {
      this.category = cat;
      this.mode = '';
    });
    this.client = client;
    this.dataMgr = dataMgr;
    this.nav = nav;
  }

  reset() {
    this.mode = '';
    this.categorySelectVM.reset();
    this.menuVisible = false;
  }

  selectCategory() {
    this.mode = 'select-category';
  }

  async initStart(opts={}) {
    try {
      this.reset();

      this.loading = true;
      this.submitting = false;

      await this.dataMgr.ready;

      this.editingEntry = null;
      this.startTime = now();
      this.category = this.categories.find(cat => cat.id === opts.category) || null;
      this.detail = opts.detail || '';
      this.comments = '';
    } catch (err) {
      console.error(err);
      alert(err);
    } finally {
      this.loading = false;
    }
  }

  async initEdit(id) {
    try {
      this.reset();

      this.loading = true;
      this.submitting = false;

      await this.dataMgr.ready;

      const entry = this.allEntries.find(e => e.id === id);

      if (!entry) {
        console.error(`Can't find entry ID '${id}'`);
        throw new Error("Can't find ID, or entry is too old");
      }

      this.editingEntry = entry;
      this.startTime = entry.start;
      this.category = this.categories.find(cat => cat.id === entry.category);
      this.detail = entry.detail || '';
      this.comments = entry.comments || '';
    } catch (err) {
      console.error(err);
      alert(err);
      this.error = `${err}`;
    } finally {
      this.loading = false;
    }
  }

  async submit() {
    try {
      if (this.submitting) {
        console.warn('Already loading');
        return;
      }

      this.submitting = true;

      if (this.editingEntry) {
        await this.update();
        this.submitting = false;
      } else {
        await this.create();
      }
    } catch (err) {
      this.submitting = false;
      console.error(err);
      this.error = `${err}`;
      alert(err);
    }
  }

  async create() {
    if (!this.category) {
      return;
    }

    await this.client.createEntry({
      category: this.category.id,
      detail: this.detail,
      comments: this.comments,
      start: this.startTime,
    });

    await this.dataMgr.fetchEntries();

    this.nav.go(this.up || '/');
  }

  async update() {
    const updatedEntry = {
      ...this.editingEntry,
      category: this.category.id,
      detail: this.detail,
      comments: this.comments,
      start: this.startTime,
    };

    await this.client.updateEntry(updatedEntry);
    await this.dataMgr.fetchEntries();

    this.editingEntry = updatedEntry;

    this.nav.go(this.up || '/');
  }

  async delete() {
    if (this.deleting || !this.editingEntry || !confirm('Are you sure you want to delete this entry?')) {
      return;
    }

    try {
      this.deleting = true;
      await this.client.deleteEntry(this.editingEntry.id);
      await this.dataMgr.fetchEntries();

      if (this.beforeEntry) {
        this.prevEntry();
      } else {
        this.newEntry();
      }
    } catch (err) {
      alert(err);
    } finally {
      this.deleting = false;
    }
  }

  resumeEntry() {
    if (this.editingEntry) {
      this.nav.go(`/entries/start?detail=${this.detail}&category=${this.category.id}${this.up ? '&up=' : ''}${encodeURIComponent(this.up)}`);
    }
  }

  nextEntry() {
    if (this.afterEntry) {
      this.nav.go(`/entries/${this.afterEntry.id}${this.up ? '?up=' : ''}${encodeURIComponent(this.up)}`);
    }
  }

  prevEntry() {
    if (this.beforeEntry) {
      this.nav.go(`/entries/${this.beforeEntry.id}${this.up ? '?up=' : ''}${encodeURIComponent(this.up)}`);
    }
  }

  newEntry() {
    if (this.beforeEntry) {
      this.nav.go(`/entries/start${this.up ? '?up=' : ''}${encodeURIComponent(this.up)}`);
    }
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }
}


const entryNav = component(({onclick, mapProps}, {div}) => (
    div({
      cls: [
        'p-2 rounded-2 text-center overflow-hidden w-100',
        mapProps(({category}) => category && shouldHaveDarkComplement(category.colour)).map({true: 'text-dark', false: 'text-white'}),
      ],
      onclick,
      style: {
        opacity: mapProps(({category, entry}) => !!(category && entry)).map({true: '1', false: '0'}),
        backgroundColor: mapProps(({category}) => category?.colour || ''),
      },
    })(
      div({cls: 'fw-bold'})(mapProps(({category}) => category?.name)),
      div({cls: 'p-1 w-100 overflow-hidden', style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis'}})(mapProps(({entry}) => entry?.detail || '\u00a0')),
      div({cls: 'datetime'})(
        mapProps(({entry, end}) => (entry && end) ? entry.start.format('HH:mm') + ' - ' + formatDiff(entry.start, end) : ''),
      )
    )
));

const datalistItem = component(({item}, {option}) => option({value: item})());

const detailList = component(({id, suggestedDetails}, {datalist, list}) => (
  list({
    elem: datalist.withProps({id}),
    itemView: datalistItem,
    items: suggestedDetails,
  })
));

const categoryItem = component(({item: category, onclick}, {div}) => (
  div({
    cls: 'rounded-3 p-3',
    style: {
      backgroundColor: category.colour,
      color: shouldHaveDarkComplement(category.colour) ? '#000000' : '#ffffff',
    },
    onclick: () => onclick(category),
  })(
    div()(category.name),
  )
));

const categorySelectorView = component(({viewmodel}, {div, list, input}) => (
  div()(
    div({cls: 'p-2 pb-0'})(
      input.text({
        cls: 'form-control',
        value: viewmodel.observable.filter,
        placeholder: 'Search',
      }),
    ),
    list({
      elem: div.withProps({
        cls: 'p-2 d-flex flex-column gap-1',
      }),
      items: viewmodel.observable.filteredCategories,
      itemView: categoryItem.withProps({
        onclick: (category) => viewmodel.setCategory(category),
      }),
    }),
  )
));

const entryView = component(({viewmodel}, {div, button, input}) => {
  const backgroundColor = obs`rgba(${viewmodel.observable.darkText.map({true: '0, 0, 0', false: '255, 255, 255'})}, 0.1)`;
  const color = viewmodel.observable.darkText.map({true: '#000000', false: '#ffffff'});

  return div()(
    div({
      cls: 'd-flex flex-column',
      style: { backgroundColor: viewmodel.observable.colour },
    })(
      div({cls: 'd-flex flex-column p-3 pb-0 gap-2'})(
        div({
          cls: 'fs-4 p-2 rounded-3',
          style: {color, backgroundColor},
          // style: {color},
          onclick: () => viewmodel.selectCategory(),
        })(viewmodel.observable.map(({category}) => category?.name || 'Select Category')),
        input.text({
          cls: [
            'form-control border-0',
            viewmodel.observable.darkText.map({
              true: 'placeholder-dark',
              false: 'placeholder-light',
            }),
          ],
          value: viewmodel.observable.detail,
          placeholder: 'Detail',
          list: 'suggested-details',
          style: {color, backgroundColor},
        }),
        detailList({
          id: 'suggested-details',
          suggestedDetails: viewmodel.observable.suggestedDetails,
        })(),
      ),
      timeDial({
        time: viewmodel.observable.startTime,
        max: viewmodel.observable.time,
        end: viewmodel.observable.endTime,
        darkText: viewmodel.observable.darkText,
      }),
    ),
    div({cls: 'd-flex flex-row p-2', style: {gap: '1em'}})(
      button({
        cls: 'col btn btn-lg btn-primary',
        visible: viewmodel.observable.isEditing,
        onclick: () => viewmodel.newEntry(),
      })('New'),
      button({
        cls: [
          'col btn btn-lg',
          viewmodel.observable.submitting.map({true: 'btn-secondary', false: 'btn-success'}),
        ],
        enabled: viewmodel.observable.canSubmit,
        onclick: () => viewmodel.submit(),
      })(viewmodel.observable.submitText),
    ),
    div({cls: 'd-flex flex-row p-2', style: {gap: '1em'}})(
      entryNav({
        category: viewmodel.observable.beforeEntryCategory,
        entry: viewmodel.observable.beforeEntry,
        end: viewmodel.observable.startTime,
        onclick: () => viewmodel.prevEntry(),
      })(),
      entryNav({
        category: viewmodel.observable.afterEntryCategory,
        entry: viewmodel.observable.afterEntry,
        end: viewmodel.observable.afterEntryEnd,
        onclick: () => viewmodel.nextEntry(),
      })(),
    ),
  );
});

const rootView = component(({viewmodel}) => (
  viewmodel.observable.mode.map(mode => {
    switch (mode) {
      case 'select-category':
        return categorySelectorView({viewmodel: viewmodel.categorySelectVM})();
      default:
        return entryView({viewmodel})();
    }
  })
));

const entryMenu = component(({viewmodel}, {div, button}) => (
  div({cls: 'p-4 d-grid gap-3 centered-content', visible: viewmodel.observable.menuVisible})(
    button({
      visible: viewmodel.observable.editingEntry,
      cls: 'btn btn-primary btn-lg',
      onclick: () => viewmodel.resumeEntry(),
    })('Resume Entry'),
    button({
      cls: [
        'btn btn-lg',
        viewmodel.observable.deleting.map({
          [true]: 'btn-secondary',
          [false]: 'btn-danger',
        }),
      ],
      onclick: () => viewmodel.delete(),
      disable: viewmodel.observable.deleting,
    })('Delete Entry'),
  )
));

export default (deps) => {
  const { setPageInfo, nav, mount } = deps;
  const viewmodel = new EntryViewmodel(deps);

  nav.route('/entries/start').do(async ({query}) => {
    viewmodel.up = query.up || '';
    await viewmodel.initStart(query);
    mount(rootView({viewmodel})(), {ref: 'entry-start'});
    setPageInfo({
      title: 'Start Entry',
      up: viewmodel.observable.upAction,
    });
  });

  nav.route('/entries/:id').do(async ({id, query}) => {
    viewmodel.up = query.up || '';
    await viewmodel.initEdit(id);
    mount(rootView({viewmodel})(), {ref: 'entry-edit'});
    const menu = entryMenu({viewmodel})();
    setPageInfo({
      title: 'Edit Entry',
      topContent: menu,
      up: viewmodel.observable.upAction,
      menuAction: () => viewmodel.toggleMenu(),
    });
  });
};
