import { internal } from '/modules/lui.js';
import { formatDiff } from '/modules/common.js';

const { Element } = internal;

const CANVAS_WIDTH = document.documentElement.clientWidth * 3;
const CANVAS_HEIGHT = CANVAS_WIDTH * 0.9;
const SIZE = CANVAS_WIDTH * 0.8;
const TRACK_WIDTH = SIZE * 0.2;
const SCALE = SIZE/1000;
const FONT = 'Lucida Grande';

class TimeDial extends Element {
  get time() {
    return this.timeBinding.get();
  }

  set time(time) {
    this.timeBinding.set(time);
  }

  get darkText() {
    return this.darkTextBinding.get();
  }

  constructor(props) {
    const {time, end, min, max, style, darkText, ...rest} = props;
    super('canvas', {
      ...rest,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      style: {
        width: '100%',
        ...style,
      },
    });

    this.ctx = this.elem.getContext('2d');

    if (this.ctx === null) {
      throw new Error('Context is null!');
    }

    this.binder.bind('max', max, (t) => {
      this.max = t;
      this.scheduleRedraw();
    });

    this.binder.bind('min', min, (t) => {
      this.min = t;
      this.scheduleRedraw();
    });

    this.fingerDown = false;
    this.movingPuck = false;

    this.timeBinding = this.binder.bind('time', time, () => {
      this.scheduleRedraw();
    });

    this.darkTextBinding = this.binder.bind('darkText', darkText, () => {
      this.scheduleRedraw();
    });

    this.binder.bind('end', end, (t) => {
      this.end = t;
      this.scheduleRedraw();
    });

    this.on(['touchstart', 'mousedown'], (e) => {
      if (this.fingerDown) {
        return;
      }

      const touch = e.touches ? e.touches[0] : e;

      const coords = getCanvasCoords(
        {x: touch.clientX, y: touch.clientY},
        this.elem.getBoundingClientRect(),
        {width: CANVAS_WIDTH, height: CANVAS_HEIGHT},
      );

      if (coords === null) return;

      if (this.isOnPuck(coords)) {
        console.log('Start on Puck');
        this.fingerDown = true;
        this.movingPuck = true;
        e.stopPropagation();
        e.preventDefault();
      }
    });

    this.on(['touchmove', 'mousemove'], (e) => {
      if (!this.fingerDown) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      const touch = e.touches ? e.touches[0] : e;

      const coords = getCanvasCoords(
        {x: touch.clientX, y: touch.clientY},
        this.elem.getBoundingClientRect(),
        {width: CANVAS_WIDTH, height: CANVAS_HEIGHT},
      );

      if (coords !== null && this.movingPuck) {
        this.movePuck(coords);
      } else {
        this.movingPuck = false;
      }
    });

    this.on(['touchend', 'mouseup'], () => {
      this.fingerDown = false;
      this.movingPuck = false;
    });
  }

  scheduleRedraw() {
    if (this.redrawPending) {
      return;
    }

    this.redrawPending = true;
    setTimeout(() => this.redraw(), 0);
  }

  isOnPuck(coords) {
    const puckPos = this.getPuckPosition();
    const delta = {x: coords.x - puckPos.x, y: coords.y - puckPos.y};

    return Math.pow(delta.x, 2) + Math.pow(delta.y, 2) <= Math.pow(TRACK_WIDTH / 2, 2);
  }

  getPuckPosition() {
    const dialRadius = (SIZE - TRACK_WIDTH) / 2;
    const angle = (2 * Math.PI) * (this.time.minute() / 60);

    const x = Math.sin(angle) * dialRadius + (CANVAS_WIDTH / 2);
    const y = -Math.cos(angle) * dialRadius + (CANVAS_HEIGHT / 2);

    return {x, y};
  }

  movePuck(coords) {
    // if (!this.isOnPuck(coords)) {
    //   this.movingPuck = false;
    //   return;
    // }

    const angle = computeAngle({
      x: coords.x - (CANVAS_WIDTH / 2),
      y: coords.y - (CANVAS_HEIGHT / 2)
    });

    const minutes = this.time.minute();

    const currentAngle = (minutes/60) * Math.PI * 2;

    const diff = relativeAngle(currentAngle, angle);

    if (Math.abs(diff) > (Math.PI/2)) {
      // More than 90 degrees off (e.g. due to reaching end time); stop the drag
      this.movingPuck = false;
      return;
    }

    let newTime;

    const diffMinutes = Math.round((Math.abs(diff) / (Math.PI * 2)) * 60);

    if (diffMinutes > 0 && diff > 0) {
      newTime = this.time.add(diffMinutes, 'minute');
    } else if (diffMinutes > 0 && diff < 0) {
      newTime = this.time.subtract(diffMinutes, 'minute');
    } else {
      return;
    }

    if ((this.min && newTime.isBefore(this.min)) || (this.max && newTime.isAfter(this.max))) {
      return;
    }

    this.time = newTime;
  }

  textCol(opacity=1) {
    return `rgba(${this.darkText ? '0, 0, 0' : '255, 255, 255'}, ${opacity})`;
  }

  redraw() {
    this.redrawPending = false;

    if (!this.time) {
      this.scheduleRedraw();
      return;
    }

    this.ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    this.ctx.beginPath();
    this.ctx.strokeStyle = this.textCol(0.2);
    this.ctx.lineWidth = TRACK_WIDTH;
    this.ctx.arc(
      CANVAS_WIDTH / 2, // x
      CANVAS_HEIGHT / 2, // y
      (SIZE - TRACK_WIDTH) / 2, // radius
      0,  // startAngle
      2 * Math.PI // endAngle
    );
    this.ctx.stroke();

    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.font = `${48 * SCALE}px ${FONT}`;
    this.ctx.fillStyle = this.textCol(0.6);
    this.ctx.fillText(this.time.format('ddd, DD/MM/YYYY'), CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 2) - 128*SCALE);
    this.ctx.font = `${128 * SCALE}px ${FONT}`;
    this.ctx.fillStyle = this.textCol();
    this.ctx.fillText(this.time.format('HH:mm'), CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);

    if (this.end) {
      this.renderDuration();
    }

    const {x, y} = this.getPuckPosition();

    this.ctx.beginPath();
    this.ctx.fillStyle = this.isOnLimit() ? this.textCol(0.6) : (this.darkText ? '#0055BB' : '#aaffee');
    this.ctx.arc(
      x,
      y,
      TRACK_WIDTH / 2,
      0,
      2 * Math.PI
    );
    this.ctx.fill();
  }

  renderDuration() {
    const diff = this.end.diff(this.time, 'minutes');
    const startAngle = (2 * Math.PI) * (this.time.minute() / 60) - Math.PI/2;
    let deltaAngle = (2*Math.PI*diff/60);

    while (deltaAngle > 0) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.textCol(0.2);
      this.ctx.lineWidth = TRACK_WIDTH;
      this.ctx.arc(
        CANVAS_WIDTH / 2, // x
        CANVAS_HEIGHT / 2, // y
        (SIZE - TRACK_WIDTH) / 2, // radius
        startAngle,
        startAngle + deltaAngle,
      );
      this.ctx.stroke();

      deltaAngle -= 2*Math.PI;
    }

    this.ctx.font = `${72 * SCALE}px ${FONT}`;
    this.ctx.fillStyle = this.textCol(0.6);
    this.ctx.fillText(
      formatDiff(this.time, this.end),
      CANVAS_WIDTH / 2,
      (CANVAS_HEIGHT / 2) + 150*SCALE,
    );
  }

  isOnLimit() {
    return (this.min && this.time.isSame(this.min)) || (this.max && this.time.isSame(this.max));
  }
}

export const timeDial = (props={}) => new TimeDial(props);

function getCanvasCoords(screenPos, bounds, size) {
  const x = (screenPos.x - bounds.left) * (size.width / bounds.width);
  const y = (screenPos.y - bounds.top) * (size.height / bounds.height);

  if (0 <= x && x < size.width && 0 <= y && y < size.height) {
    return {x, y};
  } else {
    return null;
  }
}

function computeAngle({x, y}) {
  const magnitude = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

  let angle = Math.asin(x / magnitude);

  if (y > 0) {
    angle = Math.sign(angle) * Math.PI - angle;
  }

  if (angle < 0) {
    angle += 2 * Math.PI;
  }

  return angle;
}

function relativeAngle(reference, comparison) {
  let angle = comparison - reference;

  if (angle < -Math.PI) {
    angle += 2 * Math.PI;
  }

  if (angle > Math.PI) {
    angle -= 2 * Math.PI;
  }

  return angle;
}
