import {
  component,
  Viewmodel,
  obs,
} from '/modules/lui.js';
import {
  formatDiff,
  shouldHaveDarkComplement,
  defaultColour,
  unknownCategory,
} from '/modules/common.js';
import { alertBox } from '/modules/components.js';
import { url } from '/modules/client.js';

class HomeViewmodel extends Viewmodel {
  constructor(dataMgr, clock, nav) {
    super({
      loading: false,
      error: '',

      entry: dataMgr.observable.currentEntry,
      category: dataMgr.observable.currentCategory,
      templates: dataMgr.observable.templates,
      categories: dataMgr.observable.categories,
      displayTemplates: ({templates, categories}) => {
        if (categories.length === 0) {
          return [];
        }

        const catMap = categories.reduce((cm, cat) => (cm.set(cat.id, cat), cm), new Map());

        return templates.map(t => {
          const category = catMap.get(t.category) || unknownCategory;

          return {
            category,
            detail: t.detail,
            dark: shouldHaveDarkComplement(category.colour),
          };
        });
      },

      categoryName: ({category}) => category?.name || '',
      colour: ({category}) => category?.colour || defaultColour,
      startTime: ({entry}) => entry?.start,
      detail: ({entry}) => entry?.detail || '',
      comments: ({entry}) => entry?.comments || '',

      time: clock.time,

      darkText: ({colour}) => shouldHaveDarkComplement(colour),
    });

    this.dataMgr = dataMgr;
    this.nav = nav;
  }

  editEntry() {
    this.nav.go(`/entries/${this.entry.id}`);
  }

  startTemplate(template) {
    this.nav.go(url`/entries/start?category=${template.category.id}&detail=${template.detail || ''}`);
  }
}

const templateView = component(({item, onclick}, {div}) => (
  div({
    cls: 'rounded-3 p-2 px-3',
    style: {
      backgroundColor: item.category.colour,
      color: item.dark ? '#000000' : '#ffffff',
    },
    onclick: () => onclick(item),
  })(
    div({cls: 'fs-5'})(item.category.name),
    div()(item.detail),
  )
));

const homeView = component(({viewmodel}, {a, div, list}) => (
  div()(
    div({
      cls: 'd-inline-block w-100',
      visible: viewmodel.observable.entry,
      onclick: () => viewmodel.editEntry(),
      style: {
        backgroundColor: viewmodel.observable.colour,
        color: viewmodel.observable.darkText.map({true: '#000000', false: '#ffffff'}),
      },
    })(
      div({cls: 'd-flex justify-content-between p-3 fs-1'})(
        div()(viewmodel.observable.categoryName),
        div()(viewmodel.observable.map(({startTime, time}) => startTime ? formatDiff(startTime, time) : '')),
      ),

      div({cls: 'text-center p-2 pt-0 fs-5 hide-empty'})(viewmodel.observable.detail),
      div({
        cls: 'm-3 mt-0 p-2 hide-empty rounded-3',
        style: {
          backgroundColor: obs`rgba(${viewmodel.observable.darkText.map({true: '0, 0, 0', false: '255, 255, 255'})}, 0.2)`,
        },
      })(viewmodel.observable.comments),
    ),
    alertBox({type: 'danger'})(viewmodel.observable.error),
    div({cls: 'p-2 d-flex flex-row gap-2'})(
      a({cls: 'btn btn-primary btn-lg w-100', href: '/entries'})('Entries'),
      a({cls: 'btn btn-success btn-lg w-100', href: '/entries/start'})('Start'),
    ),
    list({
      elem: div.withProps({
        cls: 'p-2 my-2 d-flex flex-column',
        style: {
          gap: '0.5em',
        },
      }),
      items: viewmodel.observable.displayTemplates,
      itemView: templateView.withProps({
        onclick: (template) => viewmodel.startTemplate(template),
      }),
    }),
    div({cls: 'p-2'})(
      a({cls: 'btn btn-outline-primary w-100', href: '/categories'})('Categories'),
    ),
  )
));

export default ({nav, setPageInfo, mount, dataMgr, clock}) => {
  const viewmodel = new HomeViewmodel(dataMgr, clock, nav);

  nav.route('/').do(() => {
    // Clear the header
    setPageInfo({title: '', up: ''});
    mount(homeView({viewmodel})());
  });
};
