import { Viewmodel } from '/modules/lui.js';
import { all } from '/modules/common.js';

// This isn't technically a Viewmodel, but it benefits acting as one (does this
// mean I should rename the Viewmodel class to something else?)
export class DataManager extends Viewmodel {
  constructor(client) {
    super({
      loaded: false,
      entries: [],
      categories: [],
      templates: [],
      error: '',

      categoryMap: ({categories}) => categories.reduce((cm, cat) => (cm.set(cat.id, cat), cm), new Map()),

      currentEntry: ({entries}) => entries.length > 0 ? entries[0] : null,
      currentCategory: ({currentEntry, categories}) => categories.find(cat => cat.id === currentEntry?.category),
    });

    this.ready = new Promise((resolve, reject) => {
      this._ready = (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }

        this._ready = null;
      };
    });

    this.client = client;
    this.observable.error.onChange(err => {
      if (err) {
        alert(err);
      }
    });
  }

  async init() {
    try {
      const { errors } = await all({
        entries: this.fetchEntries(),
        categories: this.fetchCategories(),
        templates: this.fetchTemplates(),
      });

      if (errors) {
        this.error = Object.entries(errors)
          .map(([key, err]) => {
            console.error(`[${key}]`, err);
            return `[${key}] ${err}`;
          }).join('\n');
      } else {
        this.loaded = true;
      }
    } catch (err) {
      console.error(err);
      this.error = `${err}`;
    }

    this._ready?.(this.error);
  }

  async fetchEntries() {
    this.entries = await this.client.getRecentEntries(dayjs().subtract(14, 'days'));
  }

  async fetchCategories() {
    this.categories = await this.client.getCategories();
  }

  async fetchTemplates() {
    this.templates = await this.client.getTemplates();
  }
}
