import { validate } from '/modules/validate.js';

async function withError(promise, message) {
  try {
    return await promise;
  } catch (err) {
    console.error(`withError caught the following error which it replaced with '${message}':`, err);
    throw new Error(message.replace('$1', err.message || err));
  }
}

export function url(strings, ...values) {
  if (strings.length !== values.length + 1) {
    return '';
  }

  const result = [];

  for (let i = 0; i < values.length; i++) {
    result.push(
      strings[i],
      encodeURIComponent(values[i]),
    );
  }

  result.push(strings[strings.length - 1]);

  return result.join('');
}

export class Client {
  get apiKey() {
    let apiKey = localStorage.getItem('api-key');

    if (!apiKey) {
      apiKey = prompt('Enter API Key');
    }

    if (!apiKey) {
      throw new Error('API key not provided');
    }

    localStorage.setItem('api-key', apiKey);

    return apiKey;
  }

  set apiKey(value) {
    localStorage.setItem('api-key', value);
  }

  async fetch(url, opts={}) {
    const apiKey = this.apiKey;

    const headers = {
      ...opts.headers,
      'Authorization': `Bearer ${apiKey}`,
    };

    const resp = await fetch('/api' + url, {
      ...opts,
      headers,
    });

    if (resp.status === 401) {
      // When there are multiple requests, only the first to get a 401 response
      // should prompt the user for the new key, the rest should just retry
      if (apiKey === this.apiKey) {
        this.apiKey = '';
        alert('Invalid API Key');
      }

      return await this.fetch(url, opts);
    }

    return resp;
  }

  async get(desc, url, validator) {
    try {
      const resp = await withError(this.fetch(url), 'the server is not responding');

      if (!resp.ok) {
        if (resp.status === 404) {
          return undefined;
        }

        throw new Error(`of an error from the server (${resp.status} ${resp.statusText})`);
      }

      const body = await withError(resp.json(), 'the server response could not be parsed');

      return validate.withMessage('the server response was invalid')(
        body,
        validator,
      );
    } catch (err) {
      throw new Error(`Couldn't get ${desc} because ${err.message || err || 'of unknown reasons'}`);
    }
  }

  async _putOrPost(kind, desc, url, reqBody, validator) {
    try {
      const opts = {
        method: kind,
      };

      if (reqBody !== undefined) {
        opts.headers = {
          'Content-Type': 'application/json',
        };
        opts.body = JSON.stringify(reqBody);
      }

      const resp = await withError(this.fetch(url, opts), 'the server is not responding');

      if (!resp.ok) {
        if (resp.status === 404) {
          return undefined;
        }

        throw new Error(`of an error from the server (${resp.status} ${resp.statusText})`);
      }

      if (!validator) {
        return;
      }

      const body = await withError(resp.json(), 'the server response could not be parsed');

      return validate.withMessage('the server response was invalid')(
        body,
        validator,
      );
    } catch (err) {
      throw new Error(`Couldn't ${kind.toLowerCase()} ${desc} because ${err.message || err || 'of unknown reasons'}`);
    }
  }

  post(desc, url, reqBody, validator) {
    return this._putOrPost('POST', desc, url, reqBody, validator);
  }

  put(desc, url, reqBody, validator) {
    return this._putOrPost('PUT', desc, url, reqBody, validator);
  }

  async delete(desc, url) {
    try {
      const resp = await withError(this.fetch(url, {
        method: 'DELETE',
      }), 'the server is not responding');

      if (!resp.ok) {
        if (resp.status === 404) {
          return false;
        }

        throw new Error(`of an error from the server (${resp.status} ${resp.statusText})`);
      }

      return true;
    } catch (err) {
      throw new Error(`Couldn't delete ${desc} because ${err.message || err || 'of unknown reasons'}`);
    }
  }

  getCurrentEntry() {
    return this.get('current entry',`/timer/entries/current`, entryValidator);
  }

  getRecentEntries(since) {
    return this.get('recent entries', `/timer/entries?start=${since.unix()}`, validate.isArray(entryValidator));
  }

  deleteEntry(id) {
    return this.delete('entry', url`/timer/entries/${id}`);
  }

  getCategories() {
    return this.get('categories', `/timer/categories`, validate.isArray(categoryValidator));
  }

  getTemplates() {
    return this.get('templates', `/timer/templates`, validate.isArray(templateValidator));
  }

  createEntry({category, detail, comments, start}) {
    // TODO: Single entry API
    return this.post('new entry', url`/timer/entries/bulk`, [{
      category,
      detail,
      comments,
      start: (start || dayjs()).startOf('minute').unix(),
      created: dayjs().valueOf()/1000,
    }]);
  }

  updateEntry(entry) {
    const { id, category, detail, comments, start, created } = entry;
    return this.put('update entry', url`/timer/entries/${entry.id}`, {
      id,
      category,
      detail,
      comments,
      start: start.unix(),
      created: created.valueOf()/1000,
    });
  }
}

const entryValidator = {
  id: 'string',
  category: 'string',
  detail: validate.optional('string'),
  comments: validate.optional('string'),
  start: (val) => dayjs.unix(validate(val, 'number')),
  created: (val) => dayjs.unix(validate(val, 'number')),
};

const categoryValidator = {
  id: 'string',
  name: 'string',
  colour: 'string',
};

const templateValidator = {
  category: 'string',
  detail: validate.optional('string', ''),
};
