import { internal } from '/modules/lui.js';

const colourRegexp = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/;

// TODO: Handle simple boolean values
export const buildQueryString = (query) => Object.entries(query)
  .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
  .join('&');

export const defaultColour = '#7f7f7f';
export const unknownCategory = {
  name: 'Unknown',
  colour: defaultColour,
};

export const maxTime = (a, b) => a.isAfter(b) ? a : b;
export const minTime = (a, b) => a.isBefore(b) ? a : b;

export const unwrap = (obs) => obs instanceof internal.Observable ? obs.get() : obs;

export const formatDiff = (start, end) => formatDuration(end.diff(start, 'minutes'));

export function formatDuration(dur) {
  const mins = dur % 60;
  const hours = Math.floor(dur / 60);

  return `${hours ? hours + 'h' : ''}${hours && mins ? '\u00A0' : ''}${mins || !hours ? mins + 'm' : ''}`;
}

export function rgbOpacity(hexCol, opacity) {
  const match = hexCol.toLowerCase().match(colourRegexp);

  if (!match) {
    throw new Error(`Invalid hex colour: '${hexCol}'`);
  }

  const components = Array.from(match)
    .slice(1) // Remove the complete match; only the RGB groups left
    .map(hex => parseInt(hex, 16));

  return `rgba(${components.join(', ')}, ${opacity})`;
}

export async function all(promiseObject) {
  const result = {};
  const errors = {};

  await Promise.all(Object.entries(promiseObject).map(async ([key, promise]) => {
    try {
      result[key] = await promise;
    } catch(err) {
      errors[key] = err;
    }
  }));

  if (Object.values(errors).length > 0) {
    result.errors = errors;
  }

  return result;
}

export function shouldHaveDarkComplement(colour) {
  const match = colour.toLowerCase().match(colourRegexp);

  if (!match) {
    console.error(`Invalid colour: '${colour}'`);
    return true;
  }

  const [ linR, linG, linB ] = Array.from(match)
    .slice(1) // Remove the complete match; only the RGB groups left
    .map(hex => parseInt(hex, 16)/255)
    .map(frac => frac <= 0.03928 ? frac / 12.92 : Math.pow((frac + 0.055) / 1.055, 2.4));

  const luminance = linR * 0.2126 + linG * 0.7152 + linB * 0.0722;

  return luminance > 0.45;
}

export function findEntryBefore(time, entries, allowEqual=false) {
  // Assumes entries is sorted in reverse chronological order (i.e. the first
  // entry is the most recent)

  if (entries.length === 0) {
    return null;
  }

  const isBeforeOrEqual = (entry) => entry.start.isBefore(time) || (allowEqual && entry.start.isSame(time));

  let low = 0, high = entries.length - 1;

  while (low != high) {
    let mid = Math.floor((low + high) / 2);

    if (isBeforeOrEqual(entries[mid])) {
      high = mid;
    } else {
      low = mid + 1;
    }
  }

  if (isBeforeOrEqual(entries[low])) {
    return entries[low];
  }

  return null;
}

export function findEntryAfter(time, entries) {
  // Assumes entries is sorted in reverse chronological order (i.e. the first
  // entry is the most recent)

  if (entries.length === 0) {
    return null;
  }

  let low = 0, high = entries.length - 1;

  while (low != high) {
    let mid = Math.ceil((low + high) / 2);

    if (entries[mid].start.isAfter(time)) {
      low = mid;
    } else {
      high = mid - 1;
    }
  }

  if (entries[low].start.isAfter(time)) {
    return entries[low];
  }

  return null;
}
