export class Navigation {
  constructor() {
    this.routes = [];
    this.navigateAwayHandler = null;
    this.notFoundHandler = null;
    window.onpopstate = () => {
      this.go(window.location, 'none');
    };
    this.navigateHandlers = [];
  }

  route(pattern) {
    const router = new Router(pattern);

    this.routes.push(router);

    return router;
  }

  go(url, history='push') {
    if (typeof url === 'string') {
      url = new URL(url, window.location);
    }

    if (typeof this.navigateAwayHandler === 'function') {
      // TODO: Handle window.onbeforeunload
      if (this.navigateAwayHandler() && !confirm('Are you sure? Any unsaved changes will be lost.')) {
        return;
      }

      this.navigateAwayHandler = null;
    }

    console.debug('Navigating to', url);

    const path = getFullpath(url);

    switch (history) {
      case 'push':
        window.history.pushState({}, '', path);
        break;
      case 'replace':
        window.history.replaceState({}, '', path);
        break;
      default:
        // Do nothing for any other value
    }

    this.navigated(url);
    const query = parseQuery(url.search);

    for (const route of this.routes) {
      if (route.handle(url.pathname, {query})) {

        if (url.hash) {
          this.handleHash(url.hash.substr(1));
        }

        return;
      }
    }

    if (typeof this.notFoundHandler === 'function') {
      this.notFoundHandler(url);
    } else {
      console.warn('Not found:', path);
    }
  }

  navigated(url) {
    for (const handler of this.navigateHandlers) {
      handler(url);
    }
  }

  handleHash(hash) {
    setTimeout(() => {
      const elem = document.getElementById(hash);

      if (elem) {
        elem.scrollIntoView();
      } else {
        console.error(`Couldn't scroll to #${hash} because the element couldn't be found`);
      }
    }, 50);
  }

  onNavigateAway(handler) {
    this.navigateAwayHandler = handler;
  }

  onNotFound(handler) {
    this.notFoundHandler = handler;
  }

  onNavigate(handler) {
    if (typeof handler === 'function') {
      this.navigateHandlers.push(handler);
    }
  }

  registerLinkInterceptor(rootElem) {
    new MutationObserver((mutationList) => {
      for (const changed of mutationList) {
        for (const node of changed.addedNodes) {
          if (typeof node.querySelectorAll === 'function') {
            const links = node.querySelectorAll('a');

            for (const link of links) {
              link.addEventListener('click', (e) => {
                if (link.target !== '_blank' && link.href) {
                  e.preventDefault();
                  this.go(link.href);
                  return false;
                }
              });
            }
          }
        }
      }
    }).observe(rootElem, {subtree: true, childList: true});
  }
}

const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const placeholderRegex = /^:([a-zA-Z0-9_-]+)$/;

class Router {
  constructor(pattern) {
    this.names = [];

    this.pathRegex = new RegExp('^' + escapeRegex(pattern)
      .split('/')
      .map(component => {
        const match = component.match(placeholderRegex);

        if (match) {
          this.names.push(match[1]);
          return '([^/]+)';
        } else {
          return component;
        }
      })
      .join('/')
    );

    this.handlers = [];
  }

  route(pattern) {
    const router = new Router(pattern);

    this.handlers.push(router.handler.bind(router));

    return router;
  }

  handle(path, params) {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }

    const match = path.match(this.pathRegex);

    if (!match) {
      return false;
    }

    const newParams = {...params};

    for (let i = 0; i < this.names.length; i++) {
      newParams[this.names[i]] = decodeURIComponent(match[i+1]);
    }

    const fullMatch = match[0];

    const remainingPath = path.slice(fullMatch.length);

    for (const handler of this.handlers) {
      if (handler(remainingPath, newParams)) {
        return true;
      }
    }

    return false;
  }

  do(func) {
    this.handlers.push((path, params) => {
      if (path === '') {
        func(params);
        return true;
      } else {
        return false;
      }
    });
  }
}

function getFullpath(url) {
  return url.href.substr(url.origin.length);
}

function parseQuery(queryStr) {
  if (!queryStr) {
    return {};
  }

  if (queryStr.startsWith('?')) {
    queryStr = queryStr.substr(1);
  }

  return queryStr.split('&').reduce((query, pair) => {
    const index = pair.indexOf('=');

    if (index === -1) {
      query[pair] = true;
    } else {
      query[decodeURIComponent(pair.slice(0, index))] = decodeURIComponent(pair.slice(index + 1));
    }

    return query;
  }, {});
}
