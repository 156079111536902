import {
  component,
  Viewmodel,
  obs,
} from '/modules/lui.js';
import { formatDiff, shouldHaveDarkComplement } from '/modules/common.js';


// Rollover at 5am
const dayAdjustment = 5 * 60;

class EntriesViewmodel extends Viewmodel {
  constructor(dataMgr, clock) {
    super({
      time: clock.time,
      entries: dataMgr.observable.entries,
      categories: dataMgr.observable.categories,
      categoryMap: dataMgr.observable.categoryMap,
      dayIndex: 0,

      days: ({entries, categoryMap, time}) => (
        entries.map((entry, i) => ({
          ...entry,
          duration: (i === 0 ? time : entries[i-1].start).diff(entry.start, 'minutes'),
          displayDuration: formatDiff(entry.start, i === 0 ? time : entries[i-1].start),
          category: categoryMap.get(entry.category) || {
            id: entry.category,
            name: 'Unknown',
            colour: '#7f7f7f'
          },
        })).reduce((days, entry) => {
          const start = entry.start.subtract(dayAdjustment, 'minutes').startOf('day');

          if (days.length === 0 || !days[days.length-1].start.isSame(start)) {
            days.push({start, entries: []});
          }

          days[days.length-1].entries.push(entry);

          return days;
        }, [])
      ),

      dayStart: ({days, dayIndex}) => (days.length > 0 ? days[dayIndex].start : dayjs().startOf('day')).add(dayAdjustment, 'minutes'),
      currentDay: ({days, dayIndex}) => days.length === 0 ? [] : (
        (days[dayIndex+1]?.entries?.slice(0, 1) || [])
          // The slice() is to copy the array prior to calling reverse()
          .concat(days[dayIndex].entries.slice().reverse())
      ),
    });

    this.dataMgr = dataMgr;
  }

  async init(dayStr='') {
    try {
      await this.dataMgr.ready;

      const match = dayStr.match(/^(\d+)-(\d+)-(\d+)$/);

      if (match) {
        const date = dayjs(+(new Date(parseInt(match[1]), parseInt(match[2])-1, parseInt(match[3])))).startOf('day');

        const index = this.days.findIndex(day => day.start.isSame(date));

        if (index >= 0) {
          this.dayIndex = index;
        }
      }
    } catch (err) {
      console.error(err);
      alert(err);
    }
  }
}

const entryView = component(({entry, i, entries}, {div, a}) => (
  a({
    id: entry.id,
    cls: [
      'rounded-3 m-1 mx-3 p-2 px-3 text-decoration-none d-flex flex-row gap-2 justify-content-between',
      i === 0 ? 'mt-3' : '',
      i === entries.length - 1 ? 'mb-5' : '',
    ],
    style: {
      backgroundColor: entry.category.colour,
      color: shouldHaveDarkComplement(entry.category.colour) ? '#000000' : '#ffffff',
    },
    href: `/entries/${entry.id}?up=%2Fentries`,
  })(
    div()(
      div({cls: 'fs-5'})(entry.category.name),
      div()(entry.detail),
    ),
    div({cls: 'text-end'})(
      div({cls: 'fs-5'})(entry.displayDuration),
      div()(entry.start.format('HH:mm')),
    ),
  )
));

const dayView = component(({item: day}, {div}) => (
  div({cls: 'd-flex flex-column'})(
    div({
      cls: 'fs-4 px-2 position-sticky bg-secondary text-light',
      style: {
        // This is a hack to avoid having to redesign how the header works;
        // you'll need to refactor the header in index.js to avoid using fixed
        // and instead have the content scroll beneath it
        top: '46px',
        // backgroundColor: '#aaaaaa',
      },
    })(day.start.format('dddd - MMMM D')),
    day.entries.map((entry, i, entries) => entryView({entry, i, entries})()),
  )
));

const entriesView = component(({viewmodel}, {div, list}) => (
  list({
    elem: div.withProps({
      cls: 'd-flex flex-column',
    }),
    items: viewmodel.observable.days,
    itemView: dayView,
  })
));

const scale = 1.5;

const proportionalEntryView = component(({item: entry, mapProps}, {div, span, a}) => {
  const offset = mapProps(({dayStart}) => entry.start.isAfter(dayStart) ? 0 : dayStart.diff(entry.start, 'minutes'));
  const size = offset.map(offset => scale * (entry.duration - offset));

  return a({
    cls: 'd-block text-decoration-none',
    style: {
      height: obs`${size}px`,
      color: shouldHaveDarkComplement(entry.category.colour) ? '#000000' : '#ffffff',
      backgroundColor: entry.category.colour,
      overflow: 'hidden',
    },
    href: `/entries/${entry.id}?up=${encodeURIComponent(window.location.href.slice(window.location.origin.length))}`,
  })(
    div({cls: 'm-1 d-flex flex-row justify-content-between gap-2', visible: size.map(size => size > 32)})(
      span()(entry.category.name),
      span({cls: 'text-end'})(entry.detail),
    )
  );
});

const proportionalEntriesView = component(({viewmodel}, {div, list}) => (
  div({cls: 'd-flex flex-row'})(
    viewmodel.observable.map(({dayStart}) => (
      div()(
        // This is a hack for generating [0..23]
        Array.from(new Array(24))
          .map((_, i) => dayStart.add(i, 'hours').format('HH:mm'))
          .map(time => (
            div({
              cls: 'p-1 border border-0 border-top border-end',
              style: {height: `${60*scale}px`},
            })(time)
          ))
      )
    )),
    list({
      elem: div.withProps({
        cls: 'flex-grow-1',
      }),
      items: viewmodel.observable.currentDay,
      itemView: proportionalEntryView.withProps({
        dayStart: viewmodel.observable.dayStart,
      })
    })
  )
));

export default ({nav, setPageInfo, mount, dataMgr, clock}) => {
  nav.route('/entries').do(() => {
    const viewmodel = new EntriesViewmodel(dataMgr, clock);
    viewmodel.init();
    setPageInfo({title: 'Entries'});
    mount(entriesView({viewmodel})());
  });

  nav.route('/day').do(async () => {
    const viewmodel = new EntriesViewmodel(dataMgr, clock);
    await viewmodel.init();
    setPageInfo({title: viewmodel.dayStart.format('ddd YYYY-MM-DD')});
    mount(proportionalEntriesView({viewmodel})());
  });

  nav.route('/day/:date').do(async ({date}) => {
    const viewmodel = new EntriesViewmodel(dataMgr, clock);
    await viewmodel.init(date);
    setPageInfo({title: viewmodel.dayStart.format('ddd YYYY-MM-DD')});
    mount(proportionalEntriesView({viewmodel})());
  });
};
