import { observable } from '/modules/lui.js';

const threshold = 100;

const scroll = observable.value(0);
export const refreshProgress = scroll.map(y => Math.min(1, Math.max(0, y / -threshold)));
export const willRefresh = scroll.map(y => y < -threshold);
export const isRefreshing = observable.value(false);

window.addEventListener('touchend', () => {
  if (window.scrollY < -threshold) {
    isRefreshing.set(true);
    window.location.reload();
  }
}, {passive: true});

window.addEventListener('scroll', () => {
  scroll.set(window.scrollY);
}, {passive: true});
