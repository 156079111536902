import {
  component,
  // observable,
} from '/modules/lui.js';

// export const progressBar = component(({progress, cls}, {div}) => (
//   div({cls: ['prog-bar', cls]})(
//     div({
//       cls: [
//         'prog',
//         observable.wrap(progress).map(prog => prog === 1 ? 'complete' : ''),
//       ],
//       style: {width: observable.wrap(progress).map(p => `${100*p}%`)},
//     })(),
//   )
// ));

export const overlay = component(({cls, children, ...props}, {div}) => (
  div({
    ...props,
    cls: ['bg-black opacity-50 w-100 h-100 position-fixed top-0', cls],
  })(children)
));

export const alertBox = component(({type='primary', cls, style, children, ...rest}, {div}) => (
  div({cls: [`alert alert-${type}`, cls], role: 'alert', style: {whiteSpace: 'pre', ...style}, ...rest})(
    children,
  )
));

// Icons //

function parseIcon(svg) {
  const template = document.createElement('template');
  template.innerHTML = svg.trim();
  return template.content.firstChild;
}

// See https://icons.getbootstrap.com/ for more icons
export const icons = new Proxy({

  chevronLeft: parseIcon(`
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
    </svg>
  `),

  threeDots: parseIcon(`
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
    </svg>
  `),

}, {
  get: (icns, key) => {
    if (!icns.hasOwnProperty(key)) {
      console.warn(`Can't find icon '${key}'`);
      return undefined;
    }

    return icns[key].cloneNode(true);
  },
});
