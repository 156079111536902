/* Lightweight UI framework (lui.js) */

/* State Management */

// RAW_FUNC prevents observable.state() from wrapping functions in an ObservableStateFunction
const RAW_FUNC = Symbol.for('LUI_RAW_FUNC');

const getValue = (obs) => obs instanceof Observable ? obs.get() : obs;

class Observable {
  constructor() {
    this.handlers = [];
  }

  onChange(handler) {
    this.handlers.push(handler);
    return handler;
  }

  removeOnChange(handler) {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  get() { throw new Error('Must override get'); }

  map(map) {
    if (typeof map === 'function') {
      return new ObservableFunction(map, this);
    } else if (map instanceof Map) {
      return new ObservableFunction(value => map.get(value), this);
    } else if (typeof map === 'object' && map) {
      return new ObservableFunction(value => map.hasOwnProperty(value) ? map[value] : undefined, this);
    } else {
      throw new Error('.map() only accepts objects, Maps, or functions');
    }
  }

  changed(value, prev, force=false) {
    if (!force && value === prev) return;

    for (const handler of this.handlers) {
      handler(value, prev);
    }
  }

  triggerChange() {
    this.changed(this.value, this.value, true);
  }

  toString() { return `${this.get()}`; }
}

class ObservableValue extends Observable {
  constructor(value) {
    super();
    this.value = value;
  }

  get() { return this.value; }

  // Sets the value of the ObservableValue. Calling set() with an observable
  // makes the ObservableValue use the given observable's value (even if it
  // changes) until the next call to set().
  set(value) {
    this.obs?.removeOnChange(this.handler);
    this.obs = value instanceof Observable ? value : null;
    this.handler = this.obs?.onChange(val => this._set(val));

    this._set(this.obs ? this.obs.get() : value);
  }

  _set(value) {
    const prev = this.value;
    this.value = value;
    this.changed(this.value, prev);
  }
}

// ObservableFunction allows a function to operate on multiple observable
// dependencies. It automatically unregisters change handlers from its
// dependencies when it has no change handlers of its own; this ensures this
// object is properly garbage collected when no longer needed.
class ObservableFunction extends Observable {
  constructor(func, ...dependencies) {
    super();
    this.func = func;
    this.dependencies = dependencies;
  }

  onChange(handler) {
    if (this.handlers.length === 0) {
      // Now that we our first change handler, we need to register for changes
      // from all the dependencies
      this.removeChangeHandlers = this.dependencies
        .filter(dep => dep instanceof Observable)
        .map(dep => {
          const handlerRef = dep.onChange(() => this.recompute());
          return () => dep.removeOnChange(handlerRef);
        });

      this.recompute();
    }

    return super.onChange(handler);
  }

  removeOnChange(handler) {
    super.removeOnChange(handler);

    if (this.handlers.length === 0) {
      // We no longer have any change handlers; unregister for changes from all
      // dependencies to allow the garbage collector to free this object
      for (const removeChangeHandler of this.removeChangeHandlers) {
        removeChangeHandler();
      }

      this.removeChangeHandlers = [];
    }
  }

  get() {
    if (this.handlers.length === 0) {
      // If there are no dependent handlers, then we aren't receiving change
      // events from our dependencies. Just recompute for now.
      this.recompute();
    }

    return this.value;
  }

  recompute() {
    const prev = this.value;
    this.value = this.func(...this.dependencies.map(dep => getValue(dep)));
    this.changed(this.value, prev);
  }
}

class ObservableStateFunction extends ObservableFunction {
  constructor(func, state) {
    super(func);

    const dependencies = {};

    this.value = this.func(new Proxy(state, {
      get(s, key) {
        if (!s.hasOwnProperty(key)) {
          throw new Error(`Can't find "${key}" in state (must be defined *before* functions that use it)`);
        }

        dependencies[key] = s[key];
        return getValue(s[key]);
      },
    }));

    this.state = dependencies;

    // See ObservableFunction for this.dependencies array
    this.dependencies = Object.values(dependencies).filter(dep => dep instanceof Observable);
  }

  recompute() {
    const stateValues = {};

    for (const key in this.state) {
      stateValues[key] = getValue(this.state[key]);
    }

    const prev = this.value;
    this.value = this.func(stateValues);
    this.changed(this.value, prev);
  }
}

const storageVals = ((vals={}) => (storage) => vals[storage] || (vals[storage] = new Map()))();

const registerStorageListener = once(() => {
  window.addEventListener('storage', (e) => {
    if (e.key === null) {
      storageVals(e.storageArea).forEach(obs => obs.load());
    } else {
      storageVals(e.storageArea).get(e.key)?.load();
    }
  });
});

class ObservableStoredValue extends ObservableValue {
  constructor(key, {initial, store, onLoad, onStore}={}) {
    super(initial);
    this.key = key;
    this.initial = initial;
    this.onLoad = onLoad || ((v) => v);
    this.onStore = onStore || ((v) => v);
    this.store = store || window.localStorage;

    if (storageVals(this.store).has(key)) {
      throw new Error(`Duplicate storage key '${key}'`);
    }

    storageVals(this.store).set(key, this);
    registerStorageListener();
    this.load();
  }

  set(value) {
    this.store.setItem(this.key, this.onStore(value));
    super.set(value);
  }

  load() {
    const val = this.store.getItem(this.key);
    super.set(val === null ? this.initial : this.onLoad(val));
  }

  reset() { this.set(this.initial); }
}

function setStateValue(state, key, value) {
  if (state[key] instanceof ObservableValue) {
    state[key].set(value);
    return true;
  }

  if (state.hasOwnProperty(key)) {
    throw new Error(`Can't set value for key '${key}': not an observable value (are you trying to set the value for a function?)`);
  }

  return false;
}

function buildObservableState(initialState) {
  const state = {
    set(values) {
      for (const key in values) {
        if (!setStateValue(state, key, values[key])) {
          throw new Error(`Key not found: ${key}`);
        }
      }
    },

    map: (func) => new ObservableStateFunction(func, state),
  };

  for (const key in initialState) {
    const value = initialState[key];

    if (typeof value === 'function') {
      // Raw functions accept the current state, and whatever the function
      // returns is placed directly into the state. Useful when creating custom
      // observable types (e.g. a group of fields)
      state[key] = value[RAW_FUNC] ? value(state) : new ObservableStateFunction(value, state);
    } else if (value instanceof Observable) {
      state[key] = value;
    } else {
      state[key] = new ObservableValue(value);
    }
  }

  return new Proxy(state, {
    get(state, key) {
      if (key === 'hasOwnProperty' || state.hasOwnProperty(key)) {
        return state[key];
      }

      throw new Error(`'${key}' doesn't exist in state object`);
    },
    set: () => false,
  });
}

export const observable = {
  value: (val) => new ObservableValue(val),
  storedValue: (key, options) => new ObservableStoredValue(key, options),
  func: (f, ...dependencies) => new ObservableFunction(f, ...dependencies),
  onNextChange: (f, ...dependencies) => {
    // Side effects should NOT happen inside observable functions
    const obsFunc = new ObservableFunction(() => ({}), ...dependencies);
    const handler = obsFunc.onChange(() => {
      obsFunc.removeOnChange(handler);
      f();
    });
  },
  wrap: (val) => (val instanceof Observable) ? val : new ObservableValue(val),
  state: (obj) => new Proxy(buildObservableState(obj), {
    get: (state, key) => key === 'observable' ? state : getValue(state[key]),
    set: setStateValue,
  }),
};

export class Viewmodel {
  constructor(initialState) {
    const state = buildObservableState(initialState);
    this.observable = state;

    return new Proxy(this, {
      get: (target, key) => state.hasOwnProperty(key) && state[key] instanceof Observable ? state[key].get() : target[key],
      set(target, key, value) {
        if (state.hasOwnProperty(key)) {
          setStateValue(state, key, value);
        } else {
          target[key] = value;
        }

        return true;
      },
    });
  }

  setState(values) {
    this.observable.set(values);
  }
}

export const inline = (strings, ...values) => values
  .reduce((result, value, i) => result.concat(strings[i], value), [])
  .concat(strings[strings.length-1]);

const buildTemplateString = (strings, values) => inline(strings, ...values).join('');

// Template string tag used to allow the use of observables in template strings
export const obs = (strings, ...placeholders) => {
  if (!placeholders.some(placeholder => placeholder instanceof Observable)) {
    // None of the placeholders are observable; just build and return the string
    return buildTemplateString(strings, placeholders);
  }

  return observable.func((...values) => buildTemplateString(strings, values), ...placeholders);
};

class Binding {
  constructor(observable, onChange) {
    this.observable = observable;
    this.handlerRef = this.observable.onChange((value) => onChange(value));
    this.onChange = onChange;
    this.onChange(this.observable.get());
  }

  triggerChange() {
    if (this.observable) {
      this.onChange(this.observable.get());
    } else {
      console.error('Attempt to trigger a change on an unbound binding');
    }
  }

  get() {
    return this.observable?.get();
  }

  set(value) {
    if (!this.observable) {
      console.error('Attempt to set value on an unbound binding');
    } else if (this.observable instanceof ObservableValue) {
      this.observable.set(value);
    } else {
      console.error('Attempt to set value on an observable without a setter');
    }
  }

  unbind() {
    this.observable?.removeOnChange(this.handlerRef);
    this.observable = undefined;
  }
}

function once(func) {
  let run = false;
  return (...args) => {
    if (!run) {
      run = true;
      func(...args);
    }
  };
}

class Binder {
  constructor(onObservableBinding = () => undefined) {
    this.onObservableBinding = onObservableBinding;
    this.bindings = new Map();
  }

  bind(key, value, onChange = () => undefined) {
    this.bindings.get(key)?.unbind();

    if (value instanceof Observable) {
      this.onObservableBinding(value);

      const binding = new Binding(value, onChange);
      this.bindings.set(key, binding);
      return binding;
    } else {
      onChange(value);
      return { get: () => value, set: () => undefined, triggerChange: () => onChange(value) };
    }
  }

  unbindAll() {
    this.bindings.forEach(binding => binding.unbind());
    this.bindings.clear();
  }
}

/* View Components */

function childNode(child) {
  if (child instanceof Element) {
    return child.domElement();
  } else if (child instanceof window.Element) {
    return child;
  } else if (child === undefined) {
    return document.createTextNode('');
  } else {
    return document.createTextNode(`${child}`);
  }
}

function* flatIterate(arr) {
  for (const item of arr) {
    if (Array.isArray(item)) {
      yield* flatIterate(item);
    } else {
      yield item;
    }
  }
}

class Element {
  constructor(type, props, children=[]) {
    this.elem = document.createElement(type);
    this.binder = new Binder(once(() => {
      // Runs once on the first observable binding
      this.elem.addEventListener('DOMNodeRemovedFromDocument', () => this.binder.unbindAll());
    }));
    this.children = [];
    this.observableChildID = 1;

    for (let key in props) {
      const prop = props[key];

      switch (key) {
        case 'cls':
          this.bindClass(prop);
          break;

        case 'style':
          this.bindStyle(prop);
          break;

        case 'focused':
          this.bindFocused(prop);
          break;

        case 'visible':
          this.binder.bind(key, prop, (value) => {
            this.elem.style.setProperty('display', value ? '' : 'none', value ? '' : 'important');
          });
          break;

        case 'enabled':
          this.binder.bind('disabled', prop, (value) => { this.elem.disabled = !value; });
          break;

        // Convenience aliases that fall through to the default case
        /* eslint-disable no-fallthrough */
        case 'text': key = 'innerText';
        case 'html': key = 'innerHTML';
        default:
          this.binder.bind(key, prop, key in window.HTMLElement.prototype ?
            (value) => { this.elem[key] = value; }
          :
            (value) => this.elem.setAttribute(key, value)
          );
          break;
        /* eslint-enable no-fallthrough */
      }
    }

    this.append(children);
  }

  bindClass(cls) {
    if (Array.isArray(cls)) {
      // Array of items; we need to merge them. If one or more are observable,
      // we need to merge them as an ObservableFunction.
      // TODO: Support nested arrays?
      if (cls.some(item => item instanceof Observable)) {
        // Merge all items into one observable
        cls = observable.func(
          (...vals) => vals.join(' '),
          ...cls.map(item => observable.wrap(item)),
        );
      } else {
        cls = cls.join(' ');
      }
    }

    this.binder.bind('className', cls, (value) => {
      this.elem.className = value;
    });
  }

  bindStyle(styles) {
    for (const styleKey in styles) {
      this.binder.bind(`style-${styleKey}`, styles[styleKey], (value) => {
        // TODO: Allow !important
        this.elem.style[styleKey] = value;
      });
    }
  }

  append(...children) {
    // Using document fragments means that the document flow is only
    // recalculated once when all elements are added to the DOM
    const fragment = document.createDocumentFragment();

    // flatIterate ignores nested arrays
    for (const child of flatIterate(children)) {
      if (child instanceof Element) {
        fragment.append(child.domElement());
      } else if (child instanceof Observable) {
        fragment.append(this.bindToObservableChild(child));
      } else if (child !== undefined) {
        fragment.append(child);
      }

      this.children.push(child);
    }

    this.elem.appendChild(fragment);
  }

  bindToObservableChild(observable) {
    let lastChild;

    this.binder.bind(`observable-child-${this.observableChildID++}`, observable, (value) => {
      const newChild = childNode(value);
      lastChild?.replaceWith(newChild);
      lastChild = newChild;
    });

    // Note that binder.bind() always calls the callback when the binding is created
    return lastChild;
  }

  on(event, handler) {
    if (Array.isArray(event)) {
      for (const e of event) {
        this.elem[`on${e}`] = handler;
      }
    } else {
      this.elem[`on${event}`] = handler;
    }
  }

  domElement() { return this.elem; }

  removeChildren() {
    for (const child of this.children) {
      if (child instanceof Element) {
        child.remove();
      }
    }

    this.children = [];

    removeChildren(this.elem);
  }

  bindFocused(focused) {
    if (!(focused instanceof ObservableValue)) {
      throw new Error('focused must be an ObservableValue');
    }

    const binding = this.binder.bind('focused', focused);
    this.on('focus', () => binding.set(true));
    this.on('blur', () => binding.set(false));
  }

  remove() {
    this.domElement().remove();
  }
}

class Input extends Element {
  constructor(props) {
    const {
      elemName = 'input',
      elemAttr = 'value',
      convert = (val) => val,
      // Default for display is to always replace the value
      display = (currentDisplay, newVal) => newVal,
      changeEvent = ['keyup', 'keydown', 'change'],
      type,
      value,
      ...rest
    } = props;

    super(elemName, rest);

    if (type) this.elem.type = type;
    this.convert = convert;
    this.display = display;

    // The attribute on the element to treat as the value of this input
    this.elemAttr = elemAttr;

    this.attrBinding = this.binder.bind(this.elemAttr, value || '', (val) => {
      this.elem[this.elemAttr] = this.display(this.elem[this.elemAttr], val);
    });

    this.on(changeEvent, () => {
      this.attrBinding.set(this.convert(this.elem[this.elemAttr]));
    });
  }
}

class Select extends Input {
  constructor(props) {
    const { keySelector, textSelector, disabledSelector, items, ...rest } = props;
    super({
      elemName: 'select',
      changeEvent: 'change',
      // Can't use this.keySelector here as this is called before this.keySelector is set
      display: (_, item) => String(keySelector(item)),
      convert: (key) => this.items.find(item => String(keySelector(item)) === key),
      ...rest,
    });
    this.keySelector = (item) => String(keySelector(item));
    this.textSelector = textSelector || this.keySelector;
    this.disabledSelector = disabledSelector || (() => false);

    this.binder.bind('items', items, (value) => {
      this.setItems(Array.isArray(value) ? value : []);
    });
  }

  setItems(items) {
    removeChildren(this.elem);

    this.elem.append(...items.map(item => {
      const option = document.createElement('option');
      option.innerText = this.textSelector(item);
      option.value = this.keySelector(item);
      option.disabled = this.disabledSelector(item);
      return option;
    }));

    this.items = items;

    // Removing elements causes the selected value to change, so artificially
    // trigger a change to update the handler
    this.attrBinding.triggerChange();
  }
}

/* Elements and Components */

const round = (x, dp=0) => dp > 15 ? x : (Math.round(x * 10**dp) / 10**dp);

export const objID = ((next, map) => (obj) => {
  if (!map.has(obj)) {
    map.set(obj, `obj-${next++}`);
  }

  return map.get(obj);
})(1, new WeakMap());

const __elems = {
  input: {
    text: (props={}) => new Input({type: 'text', ...props}),
    date: (props={}) => new Input({type: 'date', ...props}),
    checkbox: (props={}) => new Input({type: 'checkbox', elemAttr: 'checked', ...props}),
    textarea: (props={}) => new Input({elemName: 'textarea', ...props}),
    number: (props={}) => new Input({
      type: 'number',
      convert: (text) => round(parseFloat(text), props.decimalPlaces || 0),
      display: (currentDisplay, newVal) => {
        if (Number.isNaN(newVal) || parseFloat(currentDisplay) === newVal) {
          // Either 1) User has entered a partial value, or 2) there are
          // leading/trailing zeros
          return currentDisplay;
        }

        return newVal;
      },
      step: 10**(-props.decimalPlaces || 0),
      ...props,
    }),
    radio: ({value, variable, ...props}={}) => new Input({
      type: 'radio',
      elemAttr: 'checked',
      value: variable && variable.map(v => v === value),
      convert: () => variable && variable.set(value),
      name: variable ? objID(variable) : undefined,
      ...props,
    }),
  },
  select: (props={}) => new Select(props),
  list: ({elem, items, itemView}) => {
    const root = elem()();

    // Probably not a great idea to access internal things like this
    root.binder.bind('items', items, (value) => {
      if (Array.isArray(value)) {
        root.removeChildren();
        root.append(value.map((item, index) => itemView({item, index})()));
      } else {
        console.warn('Attempted to pass a non-array type to a list:', value);
      }
    });

    return root;
  },
};

export function mergeProps(props1, props2) {
  const merged = {...props1, ...props2};

  if (props1.hasOwnProperty('cls') && props2.hasOwnProperty('cls')) {
    merged.cls = Array.from(flatIterate([props1.cls, props2.cls]));
  }

  return merged;
}

const withProps = (elem) => (extraProps) => addWithProps((props={}) => elem(mergeProps(props, extraProps)));

// Adds a .withProps function to allow you create elements with preset attributes
function addWithProps(item) {
  if (typeof item === 'function') {
    item.withProps = withProps(item);
  } else if (typeof item === 'object') {
    for (const key in item) {
      addWithProps(item[key]);
    }
  }

  return item;
}

addWithProps(__elems);

const basicElem = (type) => addWithProps((props={}) => (...children) => new Element(type, props, children));

export const elems = new Proxy(__elems, {
  get(elms, key) {
    // Lazily add basic elements
    if (!elms.hasOwnProperty(key)) {
      elms[key] = basicElem(key);
    }

    return elms[key];
  },
});

export function component(func) {
  const comp = addWithProps((props={}) => (...children) => func({children, ...props, mapProps: (f) => new ObservableStateFunction(f, props)}, elems));
  comp.catch = (cb) => addWithProps((props={}) => (...children) => {
    try {
      return comp(props)(...children);
    } catch (err) {
      return cb({...props, err, children}, elems);
    }
  });
  return comp;
}

export function mount(root, component) {
  removeChildren(root);

  if (Array.isArray(component)) {
    root.append(...Array.from(flatIterate(component)).map(item => item instanceof Element ? item.elem : item || ''));
  } else {
    root.append(component.elem);
  }
}

function removeChildren(elem) {
  while (elem.lastChild) {
    elem.removeChild(elem.lastChild);
  }
}

/* Internal (for creating modules that extend lui.js) */

export const internal = {
  ObservableValue,
  ObservableFunction,
  Observable,
  Element,
  RAW_FUNC,
  removeChildren,
};
