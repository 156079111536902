import {
  component,
  observable,
  Viewmodel,
} from '/modules/lui.js';
import {
  buildQueryString,
  formatDuration,
  maxTime,
  rgbOpacity,
  unknownCategory,
} from '/modules/common.js';
import { EntryIterator } from '/modules/entry-iterator.js';

const updateMap = (map, key, reduce) => map.set(key, reduce(map.get(key)));

class SummaryViewmodel extends Viewmodel {
  constructor(dataMgr, start, end, exclude, timePeriodString=null) {
    super({
      start,
      end,
      entries: dataMgr.observable.entries,
      categoryMap: dataMgr.observable.categoryMap,

      timePeriodString: timePeriodString || (({start, end}) => {
        const startDayBoundary = start.isSame(start.startOf('day'));
        const endDayBoundary = end.isSame(end.startOf('day'));

        if (end.diff(start, 'days') <= 1) {
          if (startDayBoundary && endDayBoundary) {
            return start.format('dddd, Do of MMMM');
          } else {
            return start.format('dddd, Do of MMMM, HH:mm') + ' - ' + end.format('HH:mm');
          }
        } else {
          return start.format('YYYY-MM-DD') + ' - ' + end.subtract(1, 'day').format('YYYY-MM-DD');
        }
      }),

      entriesInRange: ({entries, start, end}) => new EntryIterator(entries).seek(start).until(end),

      sums: ({entriesInRange, categoryMap, start, end}) => {
        if (categoryMap.size === 0) {
          return [];
        }

        const sumMap = entriesInRange.reduce((sm, entry, i) => {
          const dur = (entriesInRange[i - 1]?.start || end).diff(maxTime(entry.start, start), 'minutes');

          return updateMap(sm, entry.category, ({duration=0, details=new Map()}={}) => ({
            duration: duration + dur,
            details: updateMap(details, entry.detail, (sum=0) => sum + dur),
          }));
        }, new Map());

        let total = end.diff(start, 'minutes');

        for (const catID of exclude) {
          total -= sumMap.get(catID)?.duration || 0;
          sumMap.delete(catID);
        }

        return Array.from(sumMap.entries()).map(([id, {duration, details}]) => ({
          ...(categoryMap.get(id) || unknownCategory),
          duration,
          details: Array.from(details.entries())
            .map(([detail, dur]) => ({
              name: detail,
              duration: dur,
              durString: formatDuration(dur),
              frac: dur / total,
            }))
            .sort((a, b) => b.duration - a.duration),
          frac: duration / total,
          durString: formatDuration(duration),
        })).sort((a, b) => b.duration - a.duration);
      },

      durationString: ({sums}) => formatDuration(sums.reduce((sum, cat) => sum + cat.duration, 0)),

    });

    this.dataMgr = dataMgr;
    this.showDetailsMap = new Map();
  }

  detailsVisibleFor(catID) {
    if (this.showDetailsMap.has(catID)) {
      return this.showDetailsMap.get(catID);
    } else {
      const obs = observable.value(false);
      this.showDetailsMap.set(catID, obs);
      return obs;
    }
  }

  async init() {
    try {
      await this.dataMgr.ready;
    } catch (err) {
      console.error(err);
      // TODO
    }
  }
}

// TODO: Custom links between pages (just URLs and text, allows router to
// determine the details)
const summaryView = component(({viewmodel, prev, next, mapProps}, {div, a}) => (
  div()(
    div({cls: 'fs-4 px-2 bg-secondary text-light text-center'})(
      viewmodel.observable.timePeriodString,
    ),
    div({cls: 'p-1 d-flex justify-content-between gap-1 fw-bold'})(
      div({style: {flex: '2 1 0'}, cls: 'text-start'})(
        a({
          cls: 'btn btn-primary w-100',
          href: mapProps(({prev}) => prev?.href),
          visible: prev,
        })(mapProps(({prev}) => prev?.text)),
      ),
      div({style: {flex: '1 1 0'}, cls: 'd-flex flex-wrap align-content-center justify-content-center fw-bold'})(viewmodel.observable.durationString),
      div({style: {flex: '2 1 0'}, cls: 'text-end'})(
        a({
          cls: 'btn btn-primary w-100',
          href: mapProps(({next}) => next?.href),
          visible: next,
        })(mapProps(({next}) => next?.text)),
      ),
    ),
    chart({sums: viewmodel.observable.sums, detailsVisibleFor: (catID) => viewmodel.detailsVisibleFor(catID)})(),
  )
));

const readCommaSepIDs = (str) => new Set(str?.split(',').filter(id => id.trim() !== '') || []);

const barComponent = component(({item, detailsVisibleFor}, {div, span}) => {
  const displayDetails = detailsVisibleFor(item.id);

  return div({
    cls: 'p-2',
    style: {backgroundColor: rgbOpacity(item.colour, 0.2)},
    onclick: () => displayDetails.set(!displayDetails.get()),
  })(
    div({cls: 'd-flex justify-content-between gap-1 fw-bold'})(
      span({style: {flex: '4 1 0'}, cls: 'text-start'})(item.name),
      span({style: {flex: '1 1 0'}, cls: 'text-center'})(item.durString),
      span({style: {flex: '1 1 0'}, cls: 'text-end'})(`${Math.round(item.frac * 100)}%`),
    ),
    div({
      cls: 'rounded-2',
      style: {
        width: `${Math.round(item.frac * 100)}%`,
        height: '1.5em',
        backgroundColor: item.colour,
      },
    })(),
    div({visible: displayDetails})(
      item.details.map(detail => [
        div({cls: 'd-flex pt-2 justify-content-between gap-1'})(
          span({style: {flex: '4 1 0'}, cls: 'text-start'})(detail.name),
          span({style: {flex: '1 1 0'}, cls: 'text-center'})(detail.durString),
          span({style: {flex: '1 1 0'}, cls: 'text-end'})(`${Math.round(detail.frac * 100)}%`),
        ),
        div({
          cls: 'rounded-2',
          style: {
            width: `${Math.round(detail.frac * 100)}%`,
            height: '1em',
            backgroundColor: item.colour, // Use colour property of parent object
          },
        })()
      ]),
    )
  );
});

const chart = component(({sums, detailsVisibleFor}, {div, list}) => (
  list({
    elem: div.withProps({
      cls: 'w-100',
    }),
    itemView: barComponent.withProps({detailsVisibleFor}),
    items: sums,
  })
));

const stepRegex = /^([a-z]+)(?::(.+))?$/i;
const unixTimestampRegex = /^\d+$/;
const dateRegex = /^\d+-\d+-\d+$/;
const datetimeRegex = /^\d+-\d+-\d+ \d+:\d+$/;

// TODO: Error handling
const steps = {
  time: (arg) => {
    let time;

    if (unixTimestampRegex.test(arg)) {
      time = dayjs.unix(parseInt(arg));
    } else if (dateRegex.test(arg)) {
      time = dayjs(arg, 'YYYY-MM-DD', true);
    } else if (datetimeRegex.test(arg)) {
      time = dayjs(arg, 'YYYY-MM-DD HH:mm', true);
    }

    if (time?.isValid()) {
      return (iter) => iter.seek(time);
    } else {
      alert(`Time: Invalid time argument: ${arg}`);
      console.error(`Time: Invalid time argument: ${arg}`, time);
      return (iter) => iter;
    }
  },
  findnext: (arg) => {
    const ids = readCommaSepIDs(arg);

    if (ids.size == 0) {
      console.error('Skip To: No IDs provided');
      return (iter) => iter;
    }

    return (iter) => iter.scanForwards(entry => ids.has(entry.category));
  },
  findprev: (arg) => {
    const ids = readCommaSepIDs(arg);

    if (ids.size == 0) {
      console.error('Stop At: No IDs provided');
      return (iter) => iter;
    }

    return (iter) => iter.scanBackwards(entry => ids.has(entry.category));
  },
  next: (arg) => (iter) => iter.next(parseInt(arg) || 1),
  prev: (arg) => (iter) => iter.prev(parseInt(arg) || 1),
};

const parseStep = (step) => {
  const match = step.trim().match(stepRegex);

  if (!match || !steps[match[1].toLowerCase()]) {
    console.error(match ? `Unknown step: ${match[1]}` : `Invalid step string: '${step}'`);
    return (iter) => iter;
  } else {
    return steps[match[1].toLowerCase()](match[2]);
  }
};

const parseSteps = (stepsStr, time, entries) => {
  const steps = stepsStr.split(';').map(parseStep);
  return observable.func(
    (time, entries) => steps.reduce((iter, step) => step(iter), new EntryIterator(entries).seek(time)).time || time,
    time,
    entries,
  );
};

export default ({nav, setPageInfo, mount, dataMgr, clock}) => {
  nav.route('/summary').do(async ({query}) => {
    let start = dayjs().startOf('day');
    let end = clock.time;

    if (query.start) {
      start = parseSteps(query.start, start, dataMgr.observable.entries);
    }

    if (query.end) {
      end = parseSteps(query.end, end, dataMgr.observable.entries);
    }

    const viewmodel = new SummaryViewmodel(
      dataMgr,
      start,
      end,
      readCommaSepIDs(query.exclude),
    );
    await viewmodel.init();
    setPageInfo({title: 'Summary'});
    mount(summaryView({
      viewmodel,
      prev: undefined,
      next: undefined,
    })());
  });

  nav.route('/summary/day/:date').do(async ({date, query}) => {
    const now = dayjs().startOf('minute');

    let start = date === 'today' ? now.startOf('day') : dayjs(date, 'YYYY-MM-DD', true);

    if (!start.isValid()) {
      alert(`Invalid date string: ${date}`);
      return;
    }

    let end = start.isSame(now.startOf('day')) ? clock.time : start.add(1, 'day');

    if (query.start) {
      start = parseSteps(query.start, start, dataMgr.observable.entries);
    } else {
      start = observable.wrap(start);
    }

    if (query.end) {
      end = parseSteps(query.end, end, dataMgr.observable.entries);
    }

    // TODO: If start > end, show "No Entries"
    const viewmodel = new SummaryViewmodel(
      dataMgr,
      start,
      end,
      readCommaSepIDs(query.exclude),
      ({start, end}) => `${start.format('HH:mm')} - ${end.format('HH:mm')}`,
    );
    await viewmodel.init();
    setPageInfo({title: start.map(start => start.format('dddd, Do of MMMM'))});
    mount(summaryView({
      viewmodel,
      prev: start.map(start => ({
        href: `/summary/day/${start.subtract(1, 'day').format('YYYY-MM-DD')}?${buildQueryString(query)}`,
        text: start.subtract(1, 'day').format('YYYY-MM-DD ddd'),
      })),
      next: observable.func(
        (start, now) => start.endOf('day').isAfter(now) ? null : ({
          href: `/summary/day/${start.add(1, 'day').format('YYYY-MM-DD')}?${buildQueryString(query)}`,
          text: start.add(1, 'day').format('YYYY-MM-DD ddd'),
        }),
        start,
        clock.time,
      ),
    })());

    // TODO: Initialise custom version of the UI with nice day-to-day controls
    // (just build the URLs for the other views and inject them into the
    // component, which will have simple links to those pages)
  });
};
