import { observable } from './lui.js';

export class Clock {
  constructor() {
    this.time = observable.value();
    this.tick();
  }

  tick() {
    this.time.set(dayjs().startOf('minute'));

    clearTimeout(this.timeout);

    const remaining = 60000 - (Date.now() % 60000);

    this.timeout = setTimeout(() => this.tick(), remaining + 100); // +100ms for good measure
  }
}
