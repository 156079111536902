export class EntryIterator {
  get prevEntry() {
    return this.entries[this.pos + 1];
  }

  get currentEntry() {
    return this.entries[this.pos];
  }

  get nextEntry() {
    return this.entries[this.pos - 1];
  }

  constructor(entries, pos=0, time=dayjs()) {
    this.entries = entries;

    this.pos = pos;
    this.time = time || this.currentEntry?.start;
    Object.freeze(this);
  }

  seek(time) {
    if (this.entries.length === 0 || this.time.isSame(time)) {
      return this;
    }

    let low = 0, high = this.entries.length - 1;

    while (low != high) {
      let mid = Math.floor((low + high) / 2);

      if (this.entries[mid].start.isAfter(time)) {
        // Too far forward; go back in time
        low = mid + 1;
      } else {
        // We might be in the right place, but we should move forward just to be
        // sure
        high = mid;
      }
    }

    if (this.entries[low].start.isAfter(time)) {
      // The given time is before the oldest entry in the list; set it to the
      // number of entries so that the current entry is undefined, but the next
      // entry is the oldest entry we have
      return new EntryIterator(this.entries, this.entries.length, time);
    } else {
      return new EntryIterator(this.entries, low, time);
    }
  }

  until(time, includeCurrent=true) {
    if (!this.time.isBefore(time)) {
      return [];
    }

    // TODO: Can I combine this with the plus one below?
    const startIter = includeCurrent ? this : this.next();
    const endIter = startIter.seek(time);

    return this.entries.slice(
      endIter.pos + (time.isSame(endIter.currentEntry?.start) ? 1 : 0),
      startIter.pos + 1,
    );
  }

  scanForwards(predicate) {
    if (this.pos < 0) {
      return this;
    }

    let i = this.pos;

    while (i >= 0) {
      if (this.entries[i] && predicate(this.entries[i], i)) {
        break;
      }

      i--;
    }

    return new EntryIterator(this.entries, i, null);
  }

  scanBackwards(predicate) {
    if (this.pos >= this.entries.length) {
      return this;
    }

    let i = this.pos;

    while (i < this.entries.length) {
      if (this.entries[i] && predicate(this.entries[i], i)) {
        break;
      }

      i++;
    }

    return new EntryIterator(this.entries, i, null);
  }

  next(delta=1) {
    if (delta <= 0) {
      return this;
    }

    return new EntryIterator(this.entries, Math.max(this.pos - delta, -1), null);
  }

  prev(delta=1) {
    if (delta <= 0) {
      return this;
    }

    // If the position time is before the oldest entry in the list, set it to
    // the number of entries so that the current entry is undefined, but the
    // next entry is the oldest entry we have
    return new EntryIterator(this.entries, Math.min(this.pos + delta, this.entries.length), null);
  }
}
